import React, { useState, useEffect } from 'react';
import Pagination from '@material-ui/lab/Pagination';

import { investorsTableHeaders } from '../../../common/tableHeades';
import { fetchInvestors } from '../_redux/httpRequests';
import NoAvailableBlock from '../../Blocks/components/NoBlocks';
import NumberOfRows from '../../Common/NumberOfRaws';
import ErrorMessage from '../../Common/ErrorMessage';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import {
  getInvestorEmail,
  getInvestorNames,
} from '../../../helpers/commonHelpers';
import { PrimaryBlueButton } from '../../../common/Buttons';

export const InvestorsPage = () => {
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);
  const [totalInvestors, setTotalInvestors] = useState();
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [investors, setInvestors] = useState();
  const [investorsPerPages, setInvestorsPerPages] = useState(10);
  const [searchValue, setSearchValue] = useState();

  const getInvestors = async (search, page, limit) => {
    setAlert({
      alertMessage: null,
      alertMessageType: null,
    });
    setLoading(true);

    try {
      const { data } = await fetchInvestors(search, page, limit);

      setTotalInvestors(data.meta.totalItems);
      setTotalPages(data.meta.totalPages);
      setCurrentPage(data?.meta.currentPage);
      setInvestorsPerPages(data?.meta.itemsPerPage);

      setInvestors(data?.items);
      setLoading(false);
    } catch (error) {
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvestors(searchValue, currentPage, investorsPerPages);
  }, []);

  const changePage = async (event, value) => {
    setCurrentPage(value);

    getInvestors(searchValue, value, investorsPerPages);
  };

  const changeLimit = async (event) => {
    const { value: selectedValue } = event.target;
    setInvestorsPerPages(Number(selectedValue));
    setCurrentPage(1);

    getInvestors(currentPage, Number(selectedValue));
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchValue(value?.trim());
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {alert?.alertMessage && <ErrorMessage alert={alert} />}
      {!investors?.length ? (
        <div className="card card-custom card-gutter-b">
          <NoAvailableBlock message="No investor so far" />
        </div>
      ) : (
        <div className="card card-custom card-gutter-b">
          <div className="card-header justify-content-between align-items-center">
            <div className="card-title">
              <h2 className="font-weight-500 text-18 ">Investors</h2>
            </div>
          </div>

          <div className="card-body">
            <div className="float-right ">
              <input
                className="border-radius-4 border-1-blue mr-2 mb-5"
                type="text"
                placeholder="Search Investor"
                name="plan"
                min={0}
                style={{
                  width: '360px',
                  padding: '5px 16px',
                  height: '40px',
                  outline: 'none',
                }}
                value={searchValue}
                onChange={handleSearch}
                autoComplete="off"
              />

              <PrimaryBlueButton
                buttonText={'Search'}
                handleOnClick={() =>
                  getInvestors(searchValue, currentPage, investorsPerPages)
                }
              />
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {investorsTableHeaders.map((header) => (
                      <th
                        key={header.columnName}
                        style={{ color: '#0071CE', minWidth: header.width }}
                        scope="col"
                      >
                        {header.columnName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {investors?.map((investor) => (
                    <tr key={investor?.id}>
                      <td className="td-font-size-14">
                        {investor?.investorPhone}
                      </td>
                      <td className="td-font-size-14">
                        {getInvestorEmail(investor)}
                      </td>
                      <td className="td-font-size-14">
                        {getInvestorNames(investor)}
                      </td>
                      <td className="td-font-size-14">
                        <a href={`/investors/${investor?.id}`}>
                          <span className="view-transactions-button py-2">
                            View Investor Profile
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div
              style={{ borderBottom: '0.6px solid #ebedf3' }}
              className="divider"
            />

            <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
              {totalPages && totalPages > 0 ? (
                <Pagination
                  page={currentPage}
                  count={totalPages}
                  onChange={changePage}
                />
              ) : (
                ''
              )}
              {totalInvestors && totalInvestors > 1 ? (
                <NumberOfRows
                  handleChangeLimit={changeLimit}
                  totalRecord={totalInvestors}
                  name="pageLimit"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
