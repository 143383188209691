/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import './offcanvas/index.scss';

import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';

import { UserProfileDropdown } from './dropdowns/UserProfileDropdown';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { getAdminDetails } from '../../../../app/helpers/getAdminDetails';
import { toAbsoluteUrl } from '../../../_helpers';
import { CustomButton } from '../../../../app/common/Buttons';
import logout from '../../../../app/modules/Auth/pages/Logout';

export function QuickUserToggler() {
  const admin = getAdminDetails();
  const { names } = admin;
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, 'extras.user.layout') === 'offcanvas',
    };
  }, [uiService]);

  const logoutClick = () => {
    const toggle = document.getElementById('kt_quick_user_toggle');
    if (toggle) {
      toggle.click();
    }
    logout();
  };

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 first-name"
            id="kt_quick_user_toggle"
          >
            <div className="d-flex align-items-center">
              <span className="d-none d-md-inline mr-5">{names}</span>
              <SVG src={toAbsoluteUrl('/media/svg/ndovu/briefcase.svg')} />
            </div>
          </div>
        </div>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}

      <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas">
        <div className="offcanvas-header d-flex align-items-center justify-content-end py-4 px-10">
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs" />
          </a>
        </div>
        <div className="px-8">
          <div className="d-flex align-items-center">
            <SVG src={toAbsoluteUrl('/media/svg/ndovu/briefcase.svg')} />
            <span className="d-none d-md-inline ml-5 text-blue font-weight-500">
              {names}
            </span>
          </div>

          <div className="text-12">
            <div className="mt-10">
              For assistance, please contact our customer care team at for
              prompt support.
            </div>

            <div className="mt-5">
              <div className="d-flex align-items-center">
                <SVG src={toAbsoluteUrl('/media/svg/ndovu/call.svg')} />
                <div className="d-none d-md-inline ml-5">+254 7123 456 789</div>
              </div>

              <div className="d-flex align-items-center mt-3">
                <SVG src={toAbsoluteUrl('/media/svg/ndovu/call.svg')} />
                <div className="d-none d-md-inline ml-5">+254 7123 456 799</div>
              </div>

              <div className="d-flex align-items-center mt-3">
                <SVG src={toAbsoluteUrl('/media/svg/ndovu/email.svg')} />
                <div className="d-none d-md-inline ml-5">support@ndovu.co</div>
              </div>
            </div>
          </div>

          <CustomButton
            buttonText={'Log out'}
            buttonIcon={'/media/svg/ndovu/logout.svg'}
            textFirstThenIcon={true}
            classes={'bg-light-blue-530 text-15 text-blue font-weight-500 mt-6'}
            buttonWidth={'w-100'}
            handleOnClick={logoutClick}
          />
        </div>
      </div>
    </>
  );
}
