import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import Pagination from '@material-ui/lab/Pagination';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';

import * as transactions from '../Transactions/_redux/transactionsReducer';
import {
  downloadPdf,
  exportToExcel,
  fetchTransactions,
} from '../Transactions/_redux/transactionsAction';

import moment from 'moment/moment';

import { Dropdown } from '../../common/Dropdown';
import { transactionsHeaders } from '../../common/tableHeades';
import { PrimaryBlueButton, PrimaryOutlinedButton } from '../../common/Buttons';
import {
  operationType,
  transactionStatusOptions,
  transactionTypeOptions,
} from '../../common/constants';
import { currencyHelper } from '../../helpers/currencyHelper';
import ErrorMessage from '../Common/ErrorMessage';
import NumberOfRows from '../Common/NumberOfRaws';
import NoAvailableBlock from '../Blocks/components/NoBlocks';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { getInvestorNames, getParams } from '../../helpers/commonHelpers';
import { ExportModalContent } from '../../common/Modal/ExportModalContent';
import { CommonModal } from '../../common/Modal/CommonModal';

const Transactions = (props) => {
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [transactionsTotalPage, setTransactionsTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsTotalRecord, setTransactionsTotalRecord] = useState(0);
  const [transactionsPerPage, setTransactionsPerPage] = useState(10);
  const [transactionFilters, setTransactionFilters] = useState({
    operationType: null,
    transactionStatus: null,
  });
  const [searchValue, setSearchValue] = useState();
  const [openDatePickerModal, setOpenDatePickerModal] = useState(false);
  const [dateValues, setDateValues] = useState();
  const [exportLoading, setExportLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const { transactions } = useSelector((state) => state?.transactions);
  const getTransactions = async (
    search,
    transactionStatus,
    operationType,
    page,
    transactionsPerPage
  ) => {
    setAlert(false);
    setLoading(true);
    try {
      const apiParams = {
        search,
        transactionStatus,
        operationType,
      };

      const filteredParams = getParams(apiParams);

      const { data } = await fetchTransactions(
        page,
        transactionsPerPage,
        filteredParams
      );

      setTransactionsTotalRecord(data.meta.totalItems);
      setTransactionsTotalPage(data.meta.totalPages);
      setCurrentPage(data?.meta.currentPage);
      setTransactionsPerPage(data?.meta.itemsPerPage);

      props.fetchTransactions(data?.items);
      setLoading(false);
    } catch (error) {
      setAlertOn(true);
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactions(
      searchValue,
      transactionFilters.transactionStatus,
      transactionFilters.operationType,
      currentPage,
      transactionsPerPage
    );
  }, []);

  const changePage = async (event, value) => {
    setCurrentPage(value);

    getTransactions(
      searchValue,
      transactionFilters.transactionStatus,
      transactionFilters.operationType,
      value,
      transactionsPerPage
    );
  };

  const changeLimit = async (event) => {
    const { value: selectedValue } = event.target;
    setTransactionsPerPage(Number(selectedValue));
    setCurrentPage(1);

    getTransactions(
      searchValue,
      transactionFilters.transactionStatus,
      transactionFilters.operationType,
      1,
      Number(selectedValue)
    );
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleFiltersChange = (e) => {
    const { value, name } = e.target;
    setTransactionFilters({ ...transactionFilters, [name]: value });

    if (name === 'transactionStatus') {
      getTransactions(
        searchValue,
        value,
        transactionFilters.operationType,
        1,
        transactionsPerPage
      );
    } else {
      getTransactions(
        searchValue,
        transactionFilters.transactionStatus,
        value,
        1,
        transactionsPerPage
      );
    }
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    setDateValues({ ...dateValues, [name]: value });
  };

  const handleExportToExcel = async () => {
    setExportLoading(true);
    setAlert({
      alertMessage: null,
      alertMessageType: null,
    });

    if (!dateValues?.startDate || !dateValues?.endDate) {
      setAlert({
        alertMessage: 'Please provide start and end date',
        alertMessageType: 'error',
      });
      setExportLoading(false);
    } else {
      const apiParams = {
        search: searchValue,
        transactionStatus: transactionFilters.transactionStatus,
        operationType: transactionFilters.operationType,
      };

      try {
        await exportToExcel(
          setExportLoading,
          `v1/partner-admin/transactions/export-transactions`,
          getParams({ ...dateValues, ...apiParams }),
          setAlert
        );
        setExportLoading(false);
        setOpenDatePickerModal(false);
      } catch (error) {
        // Handle the error response as JSON
        let errorMessage;

        if (error?.response?.request?.responseType === 'arraybuffer') {
          errorMessage = JSON.parse(
            new TextDecoder().decode(error?.response?.data)
          )?.message;
        } else {
          errorMessage = error?.response?.data?.message;
        }

        setAlert({
          alertMessage: errorMessage
            ? errorMessage
            : 'Something went wrong, please try again',
          alertMessageType: 'error',
        });

        setExportLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {alertOn && <ErrorMessage alert={alert} />}
      {transactions && !transactions.length ? (
        <div className="card card-custom card-gutter-b">
          <NoAvailableBlock message="No transaction so far" />
        </div>
      ) : (
        <div className="card card-custom card-gutter-b">
          <div className="card-header justify-content-between align-items-center">
            <div className="card-title">
              <h2 className="font-weight-bold " style={{ fontSize: '18px' }}>
                Transactions
              </h2>
            </div>

            <PrimaryOutlinedButton
              buttonText={'Export'}
              handleOnClick={() => setOpenDatePickerModal(true)}
            />
          </div>

          <div className="card-body">
            <div className="mt-5">
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <Dropdown
                    options={transactionStatusOptions}
                    label={'Status Filter'}
                    name="transactionStatus"
                    handleFiltersChange={handleFiltersChange}
                    selectedValue={transactionFilters?.transactionStatus}
                  />

                  <Dropdown
                    options={transactionTypeOptions}
                    label={'Operation Type'}
                    name="operationType"
                    handleFiltersChange={handleFiltersChange}
                    selectedValue={transactionFilters?.operationType}
                  />
                </div>

                <div>
                  <input
                    className="border-radius-4 border-1-blue mr-2 mb-5"
                    type="text"
                    placeholder="Search fund"
                    name="plan"
                    min={0}
                    style={{
                      width: '360px',
                      padding: '5px 16px',
                      height: '40px',
                      outline: 'none',
                    }}
                    value={searchValue}
                    onChange={handleSearch}
                    autoComplete="off"
                  />

                  <PrimaryBlueButton
                    buttonText={'Search'}
                    handleOnClick={() =>
                      getTransactions(
                        searchValue,
                        transactionFilters.transactionStatus,
                        transactionFilters.operationType,
                        1,
                        transactionsPerPage
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {transactionsHeaders.map((header) => (
                      <th
                        key={header.columnName}
                        style={{ color: '#0071CE', minWidth: header.width }}
                        scope="col"
                      >
                        {header.columnName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {transactions && transactions?.length
                    ? transactions?.map((transaction) => (
                        <tr key={transaction?.id}>
                          <td className="td-font-size-14">
                            {getInvestorNames(
                              transaction?.planSubscription.investor,
                              transaction?.triggerSource
                            )}
                          </td>
                          <td className="td-font-size-14">
                            <Link
                              to={`/investors/${transaction?.planSubscription.investor?.id}`}
                            >
                              {
                                transaction?.planSubscription?.investor
                                  ?.identifier
                              }
                            </Link>
                          </td>
                          <td className="td-font-size-14">
                            {moment(transaction?.createdAt).format(
                              'YYYY-MM-DD'
                            )}
                          </td>
                          <td className="td-font-size-14">
                            {transaction?.transactionStatus?.replace('_', ' ')}
                          </td>
                          <td className="td-font-size-14">{transaction?.id}</td>
                          <td className="td-font-size-14">
                            {capitalize(transaction?.operationType)}
                          </td>
                          <td className="td-font-size-14">
                            {transaction?.planSubscription?.plan?.coolName}
                          </td>
                          <td className="td-font-size-14">
                            {currencyHelper(
                              transaction?.originalAmount,
                              transaction?.originalCurrency
                            )}
                          </td>
                          <td className="td-font-size-14">
                            {transaction?.fee
                              ? currencyHelper(
                                  transaction?.fee,
                                  transaction?.fee
                                )
                              : '---'}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>

            <div
              style={{ borderBottom: '0.6px solid #ebedf3' }}
              className="divider"
            />

            <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
              {transactionsTotalPage && transactionsTotalPage > 0 ? (
                <Pagination
                  page={currentPage}
                  count={transactionsTotalPage}
                  onChange={changePage}
                />
              ) : (
                ''
              )}
              {transactionsTotalRecord && transactionsTotalRecord > 1 ? (
                <NumberOfRows
                  handleChangeLimit={changeLimit}
                  totalRecord={transactionsTotalRecord}
                  name="pageLimit"
                />
              ) : (
                ''
              )}
            </div>

            <CommonModal
              open={openDatePickerModal}
              setOpen={() => {
                setAlert({
                  alertMessage: null,
                  alertMessageType: null,
                });
                setOpenDatePickerModal(false);
              }}
              content={
                <ExportModalContent
                  handleDateChange={handleDateChange}
                  dateValues={dateValues}
                />
              }
              moreClasses={'bg-white'}
              title="Export"
              addCloseButton
              handleButtonClick={handleExportToExcel}
              alert={alert}
              loading={exportLoading}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default injectIntl(
  connect(null, { ...transactions.actions })(Transactions)
);
