import React from 'react';
import { capitalize } from 'lodash';

export const Dropdown = ({
  options,
  label,
  name,
  handleFiltersChange,
  selectedValue,
}) => {
  return (
    <select
      className="border-1-blue text-blue text-15 font-weight-500 outline-none border-radius-4 font-weight-500 mb-5 pl-3 mr-4"
      style={{ padding: '7px 4px', outline: 'none', width: '200px' }}
      name={name}
      onChange={(e) => {
        handleFiltersChange(e);
      }}
    >
      <option value={selectedValue ? selectedValue : ''}>
        {selectedValue ? capitalize(selectedValue?.replace('_', ' ')) : label}
      </option>
      {options.map((item) => (
        <option key={item.value} value={item?.value}>
          {item?.name}
        </option>
      ))}
    </select>
  );
};
