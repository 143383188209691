import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import { capitalize } from 'lodash';

import * as transactions from '../Transactions/_redux/transactionsReducer';
import { fetchInvestorTransactions } from '../Transactions/_redux/transactionsAction';

import moment from 'moment/moment';

import { investorTransactionsHeaders } from '../../common/tableHeades';

import { currencyHelper } from '../../helpers/currencyHelper';
import ErrorMessage from '../Common/ErrorMessage';
import NumberOfRows from '../Common/NumberOfRaws';
import NoAvailableBlock from '../Blocks/components/NoBlocks';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import Back from '../../common/BackButton';

const InvestorTransactions = (props) => {
  const { investorId } = useParams();

  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [transactionsTotalPage, setTransactionsTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsTotalRecord, setTransactionsTotalRecord] = useState(0);
  const [transactionsPerPage, setTransactionsPerPage] = useState(10);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const [transactions, setTransactions] = useState();

  const getTransactions = async (page, transactionsPerPage) => {
    setAlert(false);
    setLoading(true);
    try {
      const { data } = await fetchInvestorTransactions(
        investorId,
        page,
        transactionsPerPage
      );

      setTransactionsTotalRecord(data.meta.totalItems);
      setTransactionsTotalPage(data.meta.totalPages);
      setCurrentPage(data?.meta.currentPage);
      setTransactionsPerPage(data?.meta.itemsPerPage);

      setTransactions(data?.items);
      setLoading(false);
    } catch (error) {
      setAlertOn(true);
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactions(currentPage, transactionsPerPage);
  }, []);

  const changePage = async (event, value) => {
    setCurrentPage(value);

    getTransactions(value, transactionsPerPage);
  };

  const changeLimit = async (event) => {
    const { value: selectedValue } = event.target;
    setTransactionsPerPage(Number(selectedValue));
    setCurrentPage(1);

    getTransactions(1, Number(selectedValue));
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {alertOn && <ErrorMessage alert={alert} />}
      <Back
        text={'Back'}
        onClick={() => history.push(`/investors/${investorId}`)}
      />
      {transactions && !transactions.length ? (
        <div className="card card-custom card-gutter-b">
          <NoAvailableBlock message="No transaction so far" />
        </div>
      ) : (
        <div className="card card-custom card-gutter-b">
          <div className="card-header justify-content-between align-items-center">
            <div className="card-title">
              <h2 className="font-weight-bold " style={{ fontSize: '18px' }}>
                Investor Transactions
              </h2>
            </div>
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {investorTransactionsHeaders.map((header) => (
                      <th
                        key={header.columnName}
                        style={{ color: '#0071CE', minWidth: header.width }}
                        scope="col"
                      >
                        {header.columnName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {transactions && transactions?.length
                    ? transactions?.map((transaction) => (
                        <tr key={transaction?.id}>
                          <td className="td-font-size-14">{transaction?.id}</td>
                          <td className="td-font-size-14">
                            {capitalize(transaction?.operationType)}
                          </td>
                          <td className="td-font-size-14">
                            {moment(transaction?.createdAt).format(
                              'YYYY/MM/DD'
                            )}
                          </td>
                          <td className="td-font-size-14">
                            {transaction?.planSubscription?.plan?.name}
                          </td>
                          <td className="td-font-size-14">
                            {transaction?.planSubscription?.plan?.coolName}
                          </td>
                          <td className="td-font-size-14">
                            {currencyHelper(
                              transaction?.originalAmount,
                              transaction?.originalCurrency
                            )}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>

            <div
              style={{ borderBottom: '0.6px solid #ebedf3' }}
              className="divider"
            />

            <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
              {transactionsTotalPage && transactionsTotalPage > 0 ? (
                <Pagination
                  page={currentPage}
                  count={transactionsTotalPage}
                  onChange={changePage}
                />
              ) : (
                ''
              )}
              {transactionsTotalRecord && transactionsTotalRecord > 1 ? (
                <NumberOfRows
                  handleChangeLimit={changeLimit}
                  totalRecord={transactionsTotalRecord}
                  name="pageLimit"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default injectIntl(
  connect(null, { ...transactions.actions })(InvestorTransactions)
);
