import axios from 'axios';
import dotenv from 'dotenv';
import { saveAs } from 'file-saver';
import { downloadFile } from '../../../helpers/downloadFile';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_TRANSACTIONS = `${REACT_APP_BASE_URL}/v1/partner-admin/transactions`;
const GENERATE_PDF = `${REACT_APP_BASE_URL}/v1/partner-admin/transactions/export-transactions`;

const fetchTransactions = async (page, limit, params) => {
  const res = await axios.get(
    `${FETCH_TRANSACTIONS}?page=${page}&limit=${limit}`,
    { params }
  );
  return res;
};

const fetchInvestorTransactions = async (investorId, page, limit) => {
  const res = await axios.get(
    `${FETCH_TRANSACTIONS}?investorId=${investorId}&page=${page}&limit=${limit}`
  );
  return res;
};

const downloadPdf = async (payload) => {
  const response = await axios.get(GENERATE_PDF, {
    params: payload,
    responseType: 'arraybuffer', // Setting the response type to 'arraybuffer'
  });

  // Create a Blob from the response data
  const pdfToBlob = new Blob([response.data], { type: 'application/pdf' });

  // Use file-saver to save the Blob as 'statements.pdf' file
  saveAs(pdfToBlob, 'statements.pdf');
};

const exportToExcel = async (setExportLoading, url, params, setAlert) => {
  setExportLoading(true);

  const EXPORT_TO_EXCEL = `${REACT_APP_BASE_URL}/${url}`;

  const res = await axios.get(EXPORT_TO_EXCEL, {
    params,
    responseType: 'arraybuffer',
  });

  downloadFile(res);
  setExportLoading(false);
};

export {
  fetchTransactions,
  fetchInvestorTransactions,
  downloadPdf,
  exportToExcel,
};
