import React from 'react';
import Modal from '@material-ui/core/Modal';

import useModalStyles from '../../helpers/modalStyles';
import { CustomButton } from '../Buttons';
import ErrorMessage from '../../modules/Common/ErrorMessage';

export const CommonModal = ({
  open,
  setOpen,
  content,
  moreClasses,
  handleButtonClick,
  title,
  buttons,
  loading,
  alert,
}) => {
  const classes = useModalStyles({ width: '380px' });

  const body = (
    <div className={`${classes.modal} ${moreClasses} border-radius-8 pb-8`}>
      <div
        className="d-flex justify-content-between align-items-center pb-5 bg-gray-450 px-10 pt-5"
        style={{ borderRadius: '8px 8px 0 0' }}
      >
        <div className="text-15 font-weight-500 text-center">
          {title ? title : 'Success'}
        </div>

        <div onClick={() => setOpen(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M13.4 12L19.7 5.7C20.1 5.3 20.1 4.7 19.7 4.3C19.3 3.9 18.7 3.9 18.3 4.3L12 10.6L5.7 4.3C5.3 3.9 4.7 3.9 4.3 4.3C3.9 4.7 3.9 5.3 4.3 5.7L10.6 12L4.3 18.3C4.1 18.5 4 18.7 4 19C4 19.6 4.4 20 5 20C5.3 20 5.5 19.9 5.7 19.7L12 13.4L18.3 19.7C18.5 19.9 18.7 20 19 20C19.3 20 19.5 19.9 19.7 19.7C20.1 19.3 20.1 18.7 19.7 18.3L13.4 12Z"
              fill="#3E3E3E"
            />
          </svg>
        </div>
      </div>

      <div className="px-10 mt-8">
        {alert.alertMessage && <ErrorMessage alert={alert} />}
        <div className="text-center">{content}</div>

        {buttons ? (
          buttons
        ) : (
          <CustomButton
            buttonText={'Export'}
            classes={'bg-blue text-15 text-white font-weight-500 mt-6'}
            buttonWidth={'w-100'}
            handleOnClick={handleButtonClick}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalContainer}
    >
      {body}
    </Modal>
  );
};
