import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_INVESTORS = `${REACT_APP_BASE_URL}/v1/partner-admin/investors`;
const FETCH_INVESTOR_DETAILS = `${REACT_APP_BASE_URL}/v1/partner-admin/investors`;

export const fetchInvestors = async (search, page, limit) => {
  const res = await axios.get(
    `${FETCH_INVESTORS}?page=${page}&limit=${limit}`,
    { params: { search } }
  );

  return res;
};

export const fetchInvestorDetails = async (investorId) => {
  const res = await axios.get(
    `${FETCH_INVESTOR_DETAILS}/${investorId}/profile`
  );

  return res;
};
