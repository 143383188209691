import React from 'react';
import { NavLink } from 'react-router-dom';

export default function MenuItem({
  path,
  handleHover,
  handleMouseOut,
  closeLeftMenuAsideInMobile,
  hoverBackground,
  icon,
  name,
  menuName,
  menuBackground,
}) {
  return (
    <li
      className={`menu-item ${menuBackground}`}
      aria-haspopup="true"
      onMouseOver={() => handleHover(name)}
      onMouseOut={() => handleMouseOut(name)}
    >
      <NavLink
        onClick={closeLeftMenuAsideInMobile}
        to={path}
        className={`menu-link padding-bottom ${hoverBackground}`}
      >
        <span id="transaction-icon" className="svg-icon menu-icon">
          <img src={icon} alt="Transactions" />
        </span>
        <span
          id={name}
          className={`menu-text menu-text-color ${menuBackground}`}
        >
          {menuName}
        </span>
      </NavLink>
    </li>
  );
}
