import React from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../_metronic/_helpers';

export const PrimaryBlueButton = ({
  buttonText,
  classes,
  handleOnClick,
  buttonType,
  disable,
  loading,
  buttonWidth,
  changeText = true,
  buttonIcon,
}) => {
  return (
    <button
      onClick={handleOnClick}
      className={`${classes} ${buttonWidth ? buttonWidth : 'button-width'} ${
        disable ? 'bg-gray-550' : 'bg-blue blue-button-hover cursor-pointer'
      } border-radius-4 text-13 font-weight-500 height-40 border-0 text-white`}
      type={buttonType ? buttonType : 'button'}
      disabled={disable}
    >
      <div className="d-flex justify-content-center align-items-center">
        {buttonIcon ? <SVG src={toAbsoluteUrl(buttonIcon)} /> : null}
        <span className="font-weight-700 ml-2">
          {buttonText}{' '}
          {loading && <span className="ml-3 spinner spinner-white" />}
        </span>
      </div>
    </button>
  );
};

export const PrimaryOutlinedButton = ({
  buttonText,
  classes,
  handleOnClick,
  buttonType,
  disable,
  loading,
  buttonWidth,
  buttonIcon,
}) => {
  return (
    <button
      onClick={handleOnClick}
      className={`${classes} ${
        buttonWidth ? buttonWidth : 'button-width'
      } bg-white border-radius-4 text-14 text-14 height-38 cursor-pointer border-1-primary-blue text-blue`}
      type={buttonType ? buttonType : 'button'}
      disabled={disable}
    >
      <div className="d-flex justify-content-center align-items-center">
        {buttonIcon ? <SVG src={toAbsoluteUrl(buttonIcon)} /> : null}
        <span className="font-weight-700 ml-2">
          {buttonText}{' '}
          {loading && <span className="ml-3 spinner spinner-white" />}
        </span>
      </div>
    </button>
  );
};

export const CustomButton = ({
  buttonText,
  classes,
  handleOnClick,
  buttonType,
  disable,
  loading,
  buttonWidth,
  buttonIcon,
  addBorder = false,
  spinnerColor,
  textFirstThenIcon,
}) => {
  return (
    <button
      onClick={handleOnClick}
      className={`${disable ? 'bg-gray-550 text-white' : classes} ${
        buttonWidth ? buttonWidth : 'button-width'
      } ${!addBorder &&
        'border-0'} border-radius-4 text-14 height-38 cursor-pointer`}
      type={buttonType ? buttonType : 'button'}
      disabled={disable}
    >
      <div
        className={`d-flex justify-content-center align-items-center ${
          textFirstThenIcon ? 'flex-row-reverse' : ''
        }`}
      >
        {buttonIcon ? <SVG src={toAbsoluteUrl(buttonIcon)} /> : null}
        <span
          className={`font-weight-500 text-13 ${
            textFirstThenIcon ? 'mr-2' : 'ml-2'
          }`}
        >
          {buttonText}{' '}
          {loading && (
            <span
              className={`ml-3 spinner ${
                spinnerColor ? spinnerColor : 'spinner-white'
              }`}
            />
          )}
        </span>
      </div>
    </button>
  );
};
