import blockIcon from '../../../../../app/icons/LeftMenuIcons/newInvestments.svg';
import blockWhiteIcon from '../../../../../app/icons/LeftMenuIcons/investmentWhiteIcon.svg';
import transactionsIcon from '../../../../../app/icons/LeftMenuIcons/transactions.svg';
import transactionsWhiteIcon from '../../../../../app/icons/LeftMenuIcons/transactionsWhite.svg';

import investorsIcon from '../../../../../app/icons/LeftMenuIcons/investors.svg';
import investorsWhiteIcon from '../../../../../app/icons/LeftMenuIcons/investorsWhiteIcon.svg';

import homeIcon from '../../../../../app/icons/LeftMenuIcons/dashboard.svg';
import homeWhiteIcon from '../../../../../app/icons/LeftMenuIcons/dashboardWhiteIcon.svg';

const menuList = [
  {
    title: 'Home',
    name: 'home',
    path: '/home',
    icon: { white: homeWhiteIcon, blue: homeIcon },
    active: false,
    hover: false,
  },
  {
    title: 'Investors',
    name: 'investors',
    path: '/investors',
    icon: { white: investorsWhiteIcon, blue: investorsIcon },
    active: false,
    hover: false,
  },
  {
    title: 'Transactions',
    name: 'transactions',
    path: '/transactions',
    icon: { white: transactionsWhiteIcon, blue: transactionsIcon },
    active: false,
    hover: false,
  },
  {
    title: 'Blocks',
    name: 'blocks',
    path: '/blocks',
    icon: { white: blockWhiteIcon, blue: blockIcon },
    active: false,
    hover: false,
  },
];

export default menuList;
