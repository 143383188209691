import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { InvestorDetails } from './InvestorDetails';
import { InvestorsPage } from './Investors';
import investorTransactions from '../../Transactions/investorTransactions';

const Investors = ({ match }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={InvestorsPage} />
        <Route
          exact
          path={`${match.path}/:investorId`}
          component={InvestorDetails}
        />
        <Route
          exact
          path={`${match.path}/:investorId/transactions`}
          component={investorTransactions}
        />
      </Switch>
    </>
  );
};

export default Investors;
