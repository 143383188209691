import React from 'react';
import { DateInput } from './DateInput';
import moment from 'moment';

export const ExportModalContent = ({ dateValues, handleDateChange }) => {
  const todayDate = moment(new Date()).format('YYYY-MM-DD');
  return (
    <div className="">
      <div className="text-12 text-left">To export select a date range</div>
      <div
        style={{ gap: '20px' }}
        className="d-flex flex-column justify-content-center mt-5"
      >
        <DateInput
          name="startDate"
          label="Start Date"
          handleDateChange={handleDateChange}
          max={
            dateValues?.endDate
              ? moment(dateValues?.endDate).format('YYYY-MM-DD')
              : todayDate
          }
        />

        <DateInput
          name="endDate"
          label="End Date"
          handleDateChange={handleDateChange}
          classes="ml-2"
          min={
            dateValues?.startDate
              ? moment(dateValues?.startDate).format('YYYY-MM-DD')
              : null
          }
          max={todayDate}
        />
      </div>
    </div>
  );
};
