import React from 'react';
import { useHistory } from 'react-router-dom';

import backArrow from '../../icons/backArrow.svg';

const Back = ({ onClick, text }) => {
  const history = useHistory();
  return (
    <div
      style={{ width: 'fit-content' }}
      className="cursor-pointer mb-5"
      onClick={onClick ? onClick : () => history.goBack()}
    >
      <img src={backArrow} alt="Back arrow" />
      <span className="ml-1">{text}</span>
    </div>
  );
};

export default Back;
