export const transactionStatus = {
  PENDING: 'PENDING',
  ON_HOLD: 'ON_HOLD',
  ARCHIVED: 'ARCHIVED',
  COMPLETED: 'COMPLETED',
  TRANSFER_INCOMPLETE: 'TRANSFER_INCOMPLETE',
  RECONCILED: 'RECONCILED',
};

export const transactionStatusOptions = [
  {
    name: 'Pending',
    value: transactionStatus.PENDING,
  },
  { name: 'On Hold', value: transactionStatus.ON_HOLD },
  { name: 'Archived', value: transactionStatus.ARCHIVED },
  { name: 'Completed', value: transactionStatus.COMPLETED },
  { name: 'Transfer Incomplete', value: transactionStatus.TRANSFER_INCOMPLETE },
  { name: 'Reconciled', value: transactionStatus.RECONCILED },
];

export const transactionType = {
  INVESTMENT: 'GOAL',
  DIRECT: 'DIRECT',
};

export const operationType = {
  DEPOSIT: 'DEPOSIT',
  wITHDRAW: 'wITHDRAW',
  DISINVESTMENT: 'DISINVESTMENT',
};

export const transactionTypeOptions = [
  {
    name: 'Investment',
    value: operationType.DEPOSIT,
  },
  { name: 'Disinvestment', value: operationType.DISINVESTMENT },
];

export const InvestorTypes = {
  INDIVIDUAL: 'INDIVIDUAL',
  BUSINESS: 'BUSINESS',
  CHAMA: 'CHAMA',
};
