import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { PrimaryOutlinedButton } from '../../../common/Buttons';
import Back from '../../../common/BackButton';
import { fetchInvestorDetails } from '../_redux/httpRequests';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import ErrorMessage from '../../Common/ErrorMessage';
import { capitalize } from 'lodash';
import moment from 'moment';
import { currencyHelper } from '../../../helpers/currencyHelper';
import {
  getInvestorEmail,
  getInvestorNames,
} from '../../../helpers/commonHelpers';

export const InvestorDetails = () => {
  const { investorId } = useParams();

  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);
  const [investorDetails, setInvestorDetails] = useState();

  const history = useHistory();

  const getInvestorDetails = async () => {
    setAlert({
      alertMessage: null,
      alertMessageType: null,
    });
    setLoading(true);
    try {
      const { data } = await fetchInvestorDetails(investorId);

      setInvestorDetails(data?.data);
      setLoading(false);
    } catch (error) {
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvestorDetails();
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {alert?.alertMessage && <ErrorMessage alert={alert} />}
      <Back text={'Back'} onClick={() => history.push('/investors')} />
      <div className="card card-custom card-gutter-b">
        <div className="card-header justify-content-between align-items-center">
          <div className="card-title">
            <h2 className="font-weight-bold " style={{ fontSize: '18px' }}>
              Investor Profile
            </h2>
          </div>

          <PrimaryOutlinedButton
            buttonText={'View Transactions'}
            handleOnClick={() =>
              history.push(`/investors/${investorId}/transactions`)
            }
          />
        </div>

        <div className="card-body">
          <div className="text-18 text-blue font-weight-500">
            Investor Details
          </div>

          <div className="d-flex justify-content-between mt-5">
            <div>
              <div>User ID: {investorDetails?.accountOwner?.id}</div>
              <div className="mt-3">Investor ID: {investorDetails?.id}</div>
              <div className="mt-3">
                Name: {getInvestorNames(investorDetails)}
              </div>
              <div className="mt-3">
                Email Address: {getInvestorEmail(investorDetails)}
              </div>
              <div className="mt-3">
                Phone number: {investorDetails?.phoneNumber}
              </div>
              <div className="mt-3">
                Date Registered:{' '}
                {moment(investorDetails?.createdAt)?.format('YYYY/MM/DD')}
              </div>
            </div>

            <div>
              <div>
                User Status: 
                <span className="text-blue font-weight-500">
                  {capitalize(
                    investorDetails?.accountOwner?.status?.replace('_', ' ')
                  )}
                </span>
              </div>

              <div className="mt-3">
                KYC Status: 
                <span className="text-blue font-weight-500">
                  {investorDetails?.kycStatus}
                </span>
              </div>

              <div className="mt-3">
                Portfolio Value:{' '}
                {investorDetails?.apprPortfolioValue?.portfolioValue ||
                investorDetails?.apprPortfolioValue?.portfolioValue === 0
                  ? currencyHelper(
                      investorDetails?.apprPortfolioValue?.portfolioValue,
                      investorDetails?.apprPortfolioValue?.currency
                    )
                  : '---'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
