import React from 'react';
import Pagination from '@material-ui/lab/Pagination';

import { fundHeaders, transactionsHeaders } from '../../../common/tableHeades';
import { currencyHelper } from '../../../helpers/currencyHelper';
import NumberOfRows from '../../Common/NumberOfRaws';
import moment from 'moment';

export const FundInstance = ({
  fundInstance,
  transactionsTotalPage,
  currentPage,
  changePage,
  transactionsTotalRecord,
  changeLimit,
}) => {
  const planDetails = fundInstance?.plan;
  return (
    <div className="card card-custom card-gutter-b mt-5">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="bg-light-blue-140">
                {fundHeaders.map((header) => (
                  <th
                    key={header.columnName}
                    style={{ minWidth: header.width }}
                    scope="col"
                    className="text-dark-gray-550"
                  >
                    {header.columnName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-font-size-14">{planDetails?.coolName}</td>
                <td className="td-font-size-14">{planDetails?.identifier}</td>
                <td className="td-font-size-14">
                  {currencyHelper(
                    fundInstance?.investmentTotals?.totalDepositAmnt,
                    planDetails?.currency
                  )}
                </td>
                <td className="td-font-size-14">
                  {currencyHelper(
                    fundInstance?.investmentTotals?.totalWidthdrawAmnt,
                    planDetails?.currency
                  )}
                </td>
                <td className="td-font-size-14">
                  {fundInstance?.transactions?.length}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{ borderBottom: '0.6px solid #ebedf3' }}
          className="divider"
        />

        {fundInstance?.transactions && fundInstance?.transactions?.length ? (
          <>
            <div className="table-responsive mt-10">
              <table className="table">
                <thead>
                  <tr className="bg-gray-450">
                    {transactionsHeaders.map((header) => (
                      <th
                        key={header.columnName}
                        style={{ minWidth: header.width }}
                        scope="col"
                        className="text-dark-gray-550"
                      >
                        {header.columnName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {fundInstance?.transactions &&
                  fundInstance?.transactions?.length
                    ? fundInstance?.transactions?.map((transaction) => (
                        <tr key={transaction?.id}>
                          <td className="td-font-size-14">{`${transaction?.planSubscription?.investor?.accountOwner?.firstName} ${transaction?.planSubscription?.investor?.accountOwner?.lastName}`}</td>
                          <td className="td-font-size-14">
                            {
                              transaction?.planSubscription?.investor
                                ?.userReferralCode
                            }
                          </td>
                          <td className="td-font-size-14">
                            {moment(transaction?.createdAt).format(
                              'YYYY-MM-DD'
                            )}
                          </td>
                          <td className="td-font-size-14">
                            {transaction?.transactionStatus}
                          </td>
                          <td className="td-font-size-14">Reference</td>
                          <td className="td-font-size-14">
                            {transaction?.transactionType}
                          </td>
                          <td className="td-font-size-14">
                            {transaction?.planSubscription?.plan?.coolName
                              ? transaction?.planSubscription?.plan?.coolName
                              : '---'}
                          </td>
                          <td className="td-font-size-14">
                            {currencyHelper(
                              transaction?.originalAmount,
                              transaction?.originalCurrency
                            )}
                          </td>
                          <td className="td-font-size-14">
                            {transaction?.fee
                              ? currencyHelper(
                                  transaction?.fee,
                                  transaction?.fee
                                )
                              : '---'}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
            <div
              style={{ borderBottom: '0.6px solid #ebedf3' }}
              className="divider"
            />
            <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
              {transactionsTotalPage && transactionsTotalPage > 0 ? (
                <Pagination
                  page={currentPage}
                  count={transactionsTotalPage}
                  onChange={changePage}
                />
              ) : (
                ''
              )}
              {transactionsTotalRecord && transactionsTotalRecord > 1 ? (
                <NumberOfRows
                  handleChangeLimit={changeLimit}
                  totalRecord={transactionsTotalRecord}
                  name="pageLimit"
                />
              ) : (
                ''
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
