import fileSaver from 'file-saver';

export const downloadFile = (response) => {
  const dirtyFileName = response.headers['content-disposition'];
  const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
  const fileName = dirtyFileName?.match(regex)[3];

  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  fileSaver.saveAs(blob, fileName);
};
