/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import { checkIsActive } from '../../../../_helpers';

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

import menuList from './MenuList';
import MenuItem from './MenuItem';

import '../index.scss';

export function AsideMenuList({ layoutProps }) {
  const [hoverBackground, setHoverBackground] = useState('');
  const [menu, setMenu] = useState(menuList);
  const location = useLocation();

  const changeIconOnHover = (name, action) => {
    let hover = false;
    const newArray = [];
    menu.forEach((element) => {
      if (element.name === name) {
        if (action === 'mouseOver') {
          hover = true;
          element.hover = hover;
        }
        if (action === 'mouseOut') {
          hover = false;
          element.hover = hover;
        }
      }
      newArray.push(element);
    });
    setMenu(newArray);
  };

  useEffect(() => {
    const newArray = [];
    menu.forEach((element) => {
      if (location.pathname.startsWith(element.path)) {
        element.active = true;
      } else {
        element.active = false;
      }
      newArray.push(element);
    });
    setMenu(newArray);
  }, [location.pathname]);

  const handleHover = (id) => {
    const linkText = document.getElementById(id);
    linkText.classList.add('hover-color');
    setHoverBackground('hover-background');
    changeIconOnHover(id, 'mouseOver');
  };

  const handleMouseOut = (id) => {
    const linkText = document.getElementById(id);
    linkText.classList.remove('hover-color');
    setHoverBackground('');
    changeIconOnHover(id, 'mouseOut');
  };

  const closeLeftMenuAsideInMobile = () => {
    let asideItemElement = document.getElementById('kt_aside');
    let asideItemToggleBtn = document.getElementById('kt_aside_mobile_toggle');
    asideItemElement.classList.remove('aside-on');
    asideItemToggleBtn.click();
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {menu.map((item) => {
          return (
            <MenuItem
              key={item.name}
              path={item.path}
              handleHover={handleHover}
              handleMouseOut={handleMouseOut}
              closeLeftMenuAsideInMobile={closeLeftMenuAsideInMobile}
              hoverBackground={hoverBackground}
              icon={
                item.active || item.hover ? item.icon.white : item.icon.blue
              }
              name={item.name}
              menuName={item.title}
              menuBackground={item?.active ? 'active-menu-item' : ''}
            />
          );
        })}
      </ul>
    </>
  );
}
