import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_BLOCKS = `${REACT_APP_BASE_URL}/v1/partner-admin/blocks`;
const FETCH_BLOCK_DETAILS = `${REACT_APP_BASE_URL}/v1/partner-admin/blocks`;

const fetchBlocks = async (page, limit, partnerId) => {
  const res = await axios.get(`${FETCH_BLOCKS}?page=${page}&limit=${limit}`);
  return res;
};

const fetchBlockDetails = async (blockId) => {
  const res = await axios.get(`${FETCH_BLOCK_DETAILS}/${blockId}`);
  return res;
};

export { fetchBlocks, fetchBlockDetails };
