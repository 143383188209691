export const blocksHeaders = [
  { columnName: 'Block ID', width: '100px' },
  { columnName: 'Date Created', width: '150px' },
  { columnName: 'Total Transactions', width: '180px' },
  { columnName: 'Status', width: '100px' },
  { columnName: 'Transaction Type', width: '180px' },
  { columnName: 'Deposited Amount', width: '180px' },
  { columnName: 'Withdrawn Amount', width: '190px' },
  { columnName: 'Actions', width: '100px' },
];

export const transactionsHeaders = [
  { columnName: 'Investor Name', width: '150px' },
  { columnName: 'Investor Reference', width: '180px' },
  { columnName: 'Date Created', width: '150px' },
  { columnName: 'Status', width: '150px' },
  { columnName: 'Transaction ID', width: '150px' },
  { columnName: 'Operation Type', width: '180px' },
  { columnName: 'Fund', width: '165px' },
  { columnName: 'Amount', width: '160px' },
  { columnName: 'Fees', width: '150px' },
];

export const fundHeaders = [
  { columnName: 'Fund Name', width: '100px' },
  { columnName: 'Identifier', width: '150px' },
  { columnName: 'Total Deposited', width: '180px' },
  { columnName: 'Total Withdrawn', width: '100px' },
  { columnName: 'Transactions', width: '180px' },
];

export const investorsTableHeaders = [
  { columnName: 'Phone Number', width: '100px' },
  { columnName: 'Email', width: '150px' },
  { columnName: 'Name', width: '180px' },
  { columnName: 'Actions', width: '180px' },
];

export const investorTransactionsHeaders = [
  { columnName: 'Transaction ID', width: '150px' },
  { columnName: 'Operation Type', width: '180px' },
  { columnName: 'Date Executed', width: '150px' },
  { columnName: 'Fund Name', width: '150px' },
  { columnName: 'Fund Cool Name', width: '150px' },
  { columnName: 'Original Amount', width: '150px' },
];
