import { InvestorTypes } from '../common/constants';

export const getInvestorNames = (investor, triggerSource) => {
  if (triggerSource && triggerSource === 'ADMIN') {
    return 'Admin';
  }

  if (investor?.investorType === InvestorTypes.INDIVIDUAL) {
    return (
      investor?.accountOwner?.firstName + ' ' + investor?.accountOwner?.lastName
    );
  } else {
    return investor?.entityName;
  }
};

export const getInvestorEmail = (investor) => {
  if (investor?.investorType === InvestorTypes.INDIVIDUAL) {
    return investor?.accountOwner?.email;
  } else {
    return investor?.email;
  }
};

export const getParams = (params) => {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value > 0 || value?.length > 0
    )
  );

  return filteredParams;
};
