import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import * as transactions from '../../Transactions/_redux/transactionsReducer';

import moment from 'moment/moment';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import ErrorMessage from '../../Common/ErrorMessage';
import Back from '../../../common/BackButton';
import NoAvailableBlock from '../components/NoBlocks';
import { fetchBlockDetails } from '../_redux/blocksActions';
import { FundInstance } from './FundInstance';

const BlockDetails = (props) => {
  const { blockId } = useParams();

  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const [blockDetails, setBlockDetails] = useState();
  const [loading, setLoading] = useState(false);

  const { transactions } = useSelector((state) => state?.transactions);
  const history = useHistory();

  const getBlockDetails = async () => {
    try {
      setLoading(true);
      const res = await fetchBlockDetails(blockId);
      if (res.status === 200) {
        setLoading(false);
        setBlockDetails(res.data.data);
      }
    } catch (error) {
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlockDetails();
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {alert?.alertMessage && <ErrorMessage alert={alert} />}
      <Back text={'Back'} onClick={() => history.push('/blocks')} />
      {transactions && !transactions.length ? (
        <div className="card card-custom card-gutter-b">
          <NoAvailableBlock message="No transaction made so far" />
        </div>
      ) : (
        <>
          <div className="card card-custom card-gutter-b">
            <div className="card-header">
              <div className="card-title">
                <h2 className="font-weight-bold " style={{ fontSize: '18px' }}>
                  Block Details
                </h2>
              </div>
            </div>
            <div className="card-body font-weight-500">
              <div className="mb-2">Block: {blockDetails?.id}</div>
              <div className="mb-2">
                Timestamp block creation:{' '}
                {moment(blockDetails?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </div>
              <div>
                Status: 
                <span className="text-blue">{blockDetails?.status}</span>
              </div>
            </div>
          </div>
          {blockDetails?.fundInstances?.length
            ? blockDetails?.fundInstances.map((fundInstance) => (
                <FundInstance
                  key={fundInstance?.id}
                  fundInstance={fundInstance}
                />
              ))
            : null}
        </>
      )}
    </>
  );
};

export default injectIntl(
  connect(null, { ...transactions.actions })(BlockDetails)
);
